import {paths, social_externalLinks} from "routes/paths"

export const InfoCapacitacionesFranquiciados = [
    {
        url: 'https://drive.google.com/drive/folders/1-DSR-_TgTyrzaMrRVJ45nf7-gKWKhoK6?usp=drive_link',
        label: '01 - HPos Hasar',
        target: '_blank'
    },
    {
        url: 'https://drive.google.com/drive/folders/1PHsvXNhCTTNIMx9U6ApbP-Fnd8Q5YYfp?usp=drive_link',
        label: '02 - Simple Tempo',
        target: '_blank'
    },
    {
        url: paths.materialFranquiciados.url,
        label: 'VOLVER',
        target: '_self'
    }
]