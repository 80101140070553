import { Spinner } from 'components/Spinner';
import './ProductosYCatalogosFranquiciados.css'
import React,{useEffect, useState} from 'react'
import {InfoProductosYCatalogosFranquiciados} from 'utils/InfoProductosYCatalogosFranquiciados'

export const ProductosYCatalogosFranquiciados = () => {

  React.useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <div>
        <h1 className='title'>Productos Y Catálogos</h1>
        <p style={{ color: 'var(--text-color-alt)', textAlign: 'center', fontSize: '1.2rem' }}>Buscá el material clickeando en los botones</p>
        <span className='divider'></span>
        <section className='materialContainer container'>
            {InfoProductosYCatalogosFranquiciados.map((params) => {
                return(
                    <a href={params.url} key={params.url} rel='noreferrer' target={params.target} className='btnMateriales'>
                        {params.label}
                    </a>        
                )
            })}
        </section>
    </div>
  )
}