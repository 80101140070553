import {paths, social_externalLinks} from "routes/paths"

export const InfoProductosYCatalogosFranquiciados = [
    {
        url: 'https://drive.google.com/drive/folders/1VhfGOa90vuQMyA_zoB2Hqc76myT6bKjm?usp=sharing',
        label: 'PRODUCTOS LUZ AZUL Y CATÁLOGOS',
        target: '_blank'
    },
    {
        url: 'https://drive.google.com/drive/folders/1ROJEf7-6Gqsughqq4eCpsPW0ZEdXsdcO?usp=sharing',
        label: 'PRODUCTOS TERCEROS',
        target: '_blank'
    },
    {
        url: 'https://www.luz-azul.com.ar/franquiciado/3',
        label: 'FICHA TÉCNICA',
        target: '_self'
    }
]