import React from 'react'
import "./TyC.css"
import AzulinaPresidente from 'images/terminosycondiciones/AzulinaPresidente.png'

export const TyC = () => {
  return (
    <>  
        <div style={{height: '64px'}}></div>
        <h1 className="title">Terminos y Condiciones</h1>
        <hr className="divider"></hr>
        <div className="tyc__container container">
            <h2 className="subtitle">Sorteo $1.000.000 y un choripán.</h2>
            <h3>Bases y condiciones</h3>
            
            <ol className='tyc__list'>
                <li>
                    Procedimiento y reglas del sorteo.
                    <p>
                        El procedimiento para que la persona pueda participar del sorteo es a través del otorgamiento de una
                        “boleta” con el logotipo, isotipo, imagotipo y/o isologo de la marca Luz Azul en el que deberá, por un lado,
                        rellenar esa boleta con los datos que esta misma solicita y, una vez cumplido esto, debe introducir esa boleta
                        en una especie de “urna” dispuesta dentro de los locales “Luz Azul”.
                    </p>
                    <p>
                        Depositada la boleta, el participante deberá aguardar hasta el día que se realiza el sorteo para conocer el
                        ganador.  
                    </p>
                    
                    <ol>
                        <li>
                            Modos de obtener la boleta para participar.

                            <p>
                                Las personas podrán obtener la boleta para poder participar del sorteo de las siguientes formas:
                            </p>
                            <p>
                                <strong>a.</strong> Cuando se repartan por medio del personal de la empresa “Luz Azul” en espacios públicos.
                            </p>
                            <p>
                                <strong>b.</strong> Cuando se repartan por medio del personal de la empresa “Luz Azul” en locales propios.
                            </p>
                            <p>
                                Para este procedimiento, la persona que pretenda participar, no está obligada a realizar ninguna compra y la
                                boleta tampoco tiene ningún valor económico. Tanto el inicio, el desarrollo como el final del sorteo se
                                realizará de forma gratuita para el participante, sin tener la obligación de pago de ningún tipo.                               
                            </p>
                        </li>
                        
                        <li>
                            Validez de la boleta.
                            <p>
                                Para que la boleta se considere válida y, por ende, se considere legitimo el sorteo, las boletas deben cumplir
                                con los siguientes requisitos:
                            </p>
                            <p>
                                <strong>a.</strong> Las boletas siempre serán aquellas que cuenten con el logotipo(s), isotipo(s), imagotipo(s) y/o isologo(s)
                                de la marca “Luz Azul”. De no ocurrir esto, la boleta será nula, por lo que no se contabilizará en el
                                sorteo, o de suceder el sorteo, el premio no será entregado.                                 
                            </p>
                            <p>
                                <strong>b.</strong> Se debe rellenar con la información personal, completa, veraz y legible que la boleta solicita. De faltar
                                todos o alguno de estos aspectos, se la considerará “boleta en blanco” y no formará parte del sorteo o, de
                                hacerlo, el premio no será entregado.                                    
                            </p>
                            <p>
                                <strong>c.</strong> La boleta no debe contener defectos, tachaduras, objetos extraños o destrucción total o parcial. Tampoco
                                podrán ser introducidas más de una boleta por participante en un mismo momento, si pudiendo hacerlo
                                reiteradas veces, pero en diferentes días y lugares. De observarse el incumplimiento de todos o alguno de
                                estos requisitos, la boleta no será tomada en cuenta para su contabilización o, de ocurrir ello, el premio
                                no será entregado.
                            </p>
                            <p>
                                <strong>d.</strong> Puede, por circunstancias alternas y no especificadas en este apartado, que surjan otras causales para
                                determinar que una boleta no se considere válida, por lo que, no se contabilizará en el sorteo o,
                                habiéndose contabilizado, el premio no será entregado. Para esta ultima circunstancia, debe el personal
                                de la empresa, conjuntamente con escribano público, expedirse justificadamente sobre esta circunstancia.                                
                            </p>
                            <ol>
                                <li>
                                La boleta valida, que es aquella que emite exclusivamente “Luz Azul”, es la siguiente:
                                <img src={AzulinaPresidente}></img>
                                </li>
                            </ol>
                        </li>
                        <li>
                            Requisitos de validez para poder ser considera “participante”.
                            <p>
                                Podrá participar toda persona sin distinción de ningún tipo salvo dos excepciones:
                            </p>
                            <p>
                                <strong>a.</strong> Las personas menores de 18 años.                                                      
                            </p>
                            <p>
                                <strong>b.</strong> Las personas que tengan o puedan tener una relación laboral, comercial o cualquiera sea su naturaleza
                                jurídica, de manera directa o indirecta, con la empresa “Luz Azul”. Esta prohibición de participación
                                tambien se extiende a familiares de las personas por las cuales recae esta prohibición, es decir, a los
                                ascendientes, descendientes, cónyuges y hasta dentro del segundo grado de afinidad.                                            
                            </p>
                            <p>
                                Las personas comprendidas dentro del inciso “a” y/o “b” que pretendan participar del sorteo o hayan
                                introducido las boletas correspondientes a las urnas, serán nulas y no se tomarán en cuenta para ser parte del
                                sorteo. En el supuesto que una boleta resulte ganadora, pero corresponda a una persona por la cual recae esta
                                prohibición, el sorteo se realizará nuevamente las veces que sean necesarias hasta que el ganador sea un
                                participante que no encuadre estas circunstancias.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    Fecha de inicio y fin del sorteo.
                    <p>
                        La fecha de inicio del procedimiento para poder participar del sorteo comienza el jueves 1 de junio del año
                        2023 y finalizará el jueves 19 de octubre del año 2023 con la enunciación del ganador, día que se realizará el
                        sorteo.
                    </p>
                    <p>
                        Sin embargo, esta última fecha, que corresponde al día del sorteo, todas las franquicias se reservan el
                        derecho de poder retirar las urnas con una anticipación que no supere los 10 días hábiles anteriores al 19 de
                        octubre del año 2023. Es decir, hasta 10 días hábiles anteriores a la fecha de finalización, cada franquicia
                        optará, dentro de este plazo, poder retirar la respectiva urna de su local cuando le convenga, lo que significa,
                        eventualmente, que los clientes y consumidores ya no podrán participar del sorteo debido a que la urna ya no
                        se encuentra disponible para introducir las respectivas boletas y, por ende, la imposibilidad de poder
                        participar del sorteo. Lo mismo ocurrirá en aquellos casos cuando las franquicias ya no cuenten con stock de
                        las respectivas boletas.                        
                    </p>
                    <p>
                        Las boletas tambien dependen del stock que haya disponible en cada franquicia a lo largo del sorteo,
                        pudiendo suceder que, por agotamiento de estas boletas, las franquicias se reservan el derecho a dar por
                        finalizado con anterioridad a la fecha establecida el procedimiento por medio del cual los clientes o usuarios
                        participan del sorteo, quitando así las respectivas urnas de sus locales y, por ende, dando por finalizado el
                        derecho a poder participar.  
                    </p>
                    <p>
                        Todo acto que uno o varios participantes realicen en relación al sorteo antes o con posterioridad a esta fecha
                        será invalido a tales efectos.
                    </p>
                </li>
                <li>
                    Premio.
                    <p>
                        El premio del sorteo es la entrega de $1.000.000 (un millón de pesos argentinos) en efectivo y un (1)
                        choripán, entendiéndose a este último como una comida que consiste en un chorizo asado que se sirve entre
                        dos trozos de pan. Tómese un ejemplo de ello, los típicos choripanes de cancha, como los de las afueras de
                        la cancha de futbol “La Bombonera”.                     
                    </p>
                    <p>
                        El resultado del sorteo será verificado y certificado por escribano público.
                    </p>
                    <ol>
                        <li>
                            Forma en las que los participantes conocerán al ganador
                            <p>
                                El ganador del sorteo será informado tanto a través las redes sociales como de forma telefónica o vía mail,
                                en base a la información veraz que el participante brindó a la hora de volcar su información personal en la
                                “boleta” anteriormente descripto.  
                            </p>
                        </li>
                        <li>
                            Lugar y modo de entrega.
                            <p>
                                El lugar de la entrega del premio puede ser en la Ciudad Autónoma de Buenos Aires o en la Provincia de
                                Buenos Aires, República Argentina, específicamente en aquel lugar que determine el personal de la empresa
                                “Luz Azul”, debiendo el ganador asistir al lugar al que “Luz Azul” le indique en los términos y modos que
                                le convenga.
                            </p>
                            <p>
                                El premio será entregado personalmente al o la ganadora del sorteo, nunca pudiendo un tercero presentarse
                                en su nombre, a menos que exista representación legal, convencional o por medio de apoderado con la
                                documentación correspondiente para certificar esta circunstancia.
                            </p>
                            <p>
                                Es un requisito sustancial que, inmediatamente antes de la entrega del premio, el o la ganadora deberá firmar
                                el correspondiente comprobante que evidencie el hecho de la entrega del premio.
                            </p>
                        </li>
                        <li>
                            Acreditación de la información personal del o la ganadora.
                            <p>
                                La persona que gane el sorteo debe acreditar por medio de D.N.I, pasaporte o cualquier otro documento
                                oficial que la información personal introducida en la “boleta” resulte de su persona. De no acreditarse la
                                identidad de la persona con la información volcada en la “boleta”, por los motivos que sean, el premio no
                                será entregado.
                            </p>
                        </li>
                        <li>
                            Tiempo límite para reclamar el premio.
                            <p>
                                A partir del día siguiente a la fecha en la que se hizo conocer al ganador del premio, comenzará a correr un
                                plazo de 14 días corridos para reclamarlo, acreditar su identidad y asistir al lugar de entrega. Vencido este
                                plazo, el ganador ya no podrá reclamar el premio y este se dará por perdido. 
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    Información personal. 
                    <p>
                        <strong>a.</strong> Luz Azul verificará su identidad a través de canales legales establecidos, ya sean mediante los medios
                        automáticos para la verificación de la identidad, como mediante la solicitud de información o
                        documentación.  
                    </p>
                    <p>
                        <strong>b.</strong> Toda información proporcionada deberá ser precisa, completa y veraz. De lo contrario, nos reservamos
                        el derecho a eliminar del sorteo a esa persona, o no entregarle el premio en caso que ello suceda.
                    </p>
                    <p>
                        <strong>c.</strong> Al aceptar las condiciones, usted acepta que estamos legitimados para llevar a cabo cualquier gestión
                        para la correcta identificación de identidad u otros controles de verificación que se requiera. Cada
                        participante acepta proporcionar la información que resulte necesaria para identificar y ejercer las
                        verificaciones y controles que resulten necesarios.

                    </p>
                    <p>
                        <strong>d.</strong> Luz Azul se adecuará en todo momento a la normativa aplicable al tratamiento de los datos personales
                        que se proporcione. Los datos personales serán tratados de acuerdo con nuestra política de privacidad.  
                    </p>
                    <p>
                        <strong>e.</strong> Todos los datos personales serán tratados de manera estrictamente confidencial y no se compartirán ni
                        cederán a terceros salvo para su procesamiento por medios informáticos a los fines del sorteo.
                    </p>
                    <p>
                        <strong>f.</strong> Los datos personales de quienes deseen participar serán tratados en estricto cumplimiento de la Ley
                        25.326 y su decreto reglamentario 1558/01, con la única finalidad del correcto desarrollo del sorteo.
                    </p>
                    <p>
                        <strong>g.</strong> La agencia de acceso a la información pública es el organismo de control de la ley 25.326 y tiene
                        potestad para recibir y tramitar denuncias por incumplimiento a la misma.
                    </p>
                </li>
                <li>
                    Reclamos, resolución de controversias, ley vigente y jurisdicción.
                    <p>
                    <strong>a.</strong>En caso de reclamación o conflicto, póngase en con nosotros, por medio del siguiente link: contacto@luz-azul.com.ar
                    </p>
                    <p>
                    <strong>b.</strong> Luz Azul tratará de resolver las reclamaciones provenientes de los clientes dentro del plazo de un mes desde la presentación de las mismas.
                    </p>
                    <p>
                        <strong>c.</strong> En caso de que la misma no se resuelva o el cliente no esté de acuerdo con la solución brindad por Luz Azul, ambas partes pueden remitir la disputa a un organismo de arbitraje.
                    </p>
                    <p>
                        <strong>d.</strong> El participante irrevocablemente acepta que los tribunales de la Provincia de Buenos Aires tendrán
                        jurisdicción exclusiva en la determinación de cualquier disputa surgida en relación a estas condiciones
                        generales. Sin perjuicio de lo que precede, Luz Azul se reserva el derecho a iniciar acciones contra el
                        cliente, participante o terceros solidarios, en los Tribunales Ordinarios del Departamento Judicial de
                        Azul, Provincia de Buenos Aires.
                    <br></br>
                        Si alguna disposición contenida en estas condiciones generales fuera reconocida como no válida o no
                        aplicable por un tribunal o por un órgano administrativo de jurisdicción competente, dicha invalidez o no
                        aplicabilidad no afectará las demás disposiciones sobre estas condiciones generales, las cuales
                        permanecerán en vigencia.
                    </p>
                    <p>
                        <strong>e.</strong> Los participantes mantendrán indemne a su costa al organizador y a su personal contra todos los juicios,
                        reclamos, demandas y responsabilidades de toda naturaleza o especie, incluidas las costas y gastos que
                        se deriven de actos u omisiones de los participantes o de su personal o de otras personas que ante él
                        fueren responsables con motivo o en ocasión especialmente con relación a la participación del presente
                        sorteo.                         
                    </p>
                </li>
                <li>
                    Aceptación de las bases y condiciones.
                    <p>
                        <strong>a.</strong> Este cuerpo, de bases y condiciones, será promocionado conjuntamente con los elementos de difusión
                        con los que la empresa “Luz Azul” publicite el sorteo por los medios correspondientes.  
                    </p>
                    <p>
                        <strong>b.</strong> Realizado el procedimiento para poder participar del sorteo, como lo establece el punto 1 de este
                        documento, la persona pasará a ser “participante”. A partir de ese momento, acepta quedar vinculado a
                        las condiciones, reglas, bases y condiciones que este documento establece. Las personas intervinientes
                        en esta promoción por su sola participación aceptan de pleno derecho todas y cada una de las
                        disposiciones descriptas en estas bases y condiciones.
                    </p>
                    <p>
                        <strong>c.</strong> Este documento puede ser modificado en partes o en su todo cuando razonablemente la empresa “Luz
                        Azul” así lo requiera. Cuando circunstancias imprevistas o de fuerza mayor lo justifiquen, “Luz Azul”
                        podrá suspender o dar por finalizado el/los sorteo/s, no teniendo los participantes derechos a reclamo o
                        resarcimiento alguno. 
                    </p>
                    <p>
                        <strong>d.</strong> Luz Azul se exime de toda responsabilidad por cualquier daño que puedan sufrir los participantes con
                        motivo o en ocasión del concurso.  
                    </p>
                </li>
            </ol>
        </div>
    </>
  )
}
