import {paths, social_externalLinks} from "routes/paths"

export const InfoPreciosFranquiciados = [
    {
        url: 'https://www.luz-azul.com.ar/precios/',
        label: '01 - LISTA PÚBLICO',
        target: '_self'
    },
    {
        url: 'https://www.luz-azul.com.ar/precios/listaprecios/7265431598434',
        label: '02 - LISTA MAYORISTA',
        target: '_self'
    },
    {
        url: 'https://www.luz-azul.com.ar/precios/listaprecios/48743165164863458',
        label: '03 - LISTA PARA FRANQUICIADOS',
        target: '_self'
    },
    {
        url: 'https://www.luz-azul.com.ar',
        label: '04 - CÓDIGOS QR',
        target: '_self'
    },
    {
        url: paths.materialFranquiciados.url,
        label: 'VOLVER',
        target: '_self'
    }
]