import React from 'react'
import './PromosVigentesFranquiciados.css'
import {InfoPromosVigentesFranquiciados} from 'utils/InfoPromosVigentesFranquiciados'

export const PromosVigentesFranquiciados = () => {

    React.useEffect(() => {
        window.scrollTo(0, 0)
    })

  return (
    <div>
        <h1 className='title'>Promos Vigentes</h1>
        <p style={{ color: 'var(--text-color-alt)', textAlign: 'center', fontSize: '1.2rem' }}>Buscá el material clickeando en los botones</p>
        <span className='divider'></span>
        <section className='materialContainer container'>
            {InfoPromosVigentesFranquiciados.map((params) => {
                return(
                    <a href={params.url} key={params.url} rel='noreferrer' target={params.target} className='btnMateriales'>
                        {params.label}
                    </a>        
                )
            })}
        </section>
    </div>
  )
}
