import {paths, social_externalLinks} from "routes/paths"

export const InfoPrensaFranquiciados = [
    {
        url: 'https://www.luz-azul.com.ar/prensa/',
        label: '01 - PRENSA',
        target: '_self'
    },
    {
        url: 'https://youtu.be/Wy1MQDSzRBs?feature=shared',
        label: '02 - TV',
        target: '_blank'
    }
]