import React, {useState, useEffect} from 'react'
import './DashboardAdmin.css'
import Switch from './Switch';
import { Api } from 'utils/api'
import { API_WEB } from 'utils/api_web';
import { Spinner } from 'components/Spinner';
import { Alert } from 'components/MessageBox/Alert';
import {Modal} from "components/Modal/Modal"
import {ProductSection} from 'components/DashboardView/ProductSection'
import {SucursalesSection} from 'components/DashboardView/SucursalesSection'

export const DashboardAdmin = () => {

  const [previewImage, setPreviewImage] = useState('');
  const [previewImageMovil, setPreviewImageMovil] = useState('');
  const [previewImagePromo, setPreviewImagePromo] = useState('');
  const [carouselImg,setCarouselImg] = useState()  //Imagen Banner PC
  const [bannerMovil, setBannerMovil] = useState() // Imagen Banner Movil
  const [promoImg,setPromoImg] = useState()  //Imagen Banner PC
  const [carouselActive,setCarouselActive] = useState({})
  const [activeModalCarousel, setActiveModalCarousel] = useState(false)
  
  const {getToken,logout, http, URI} = Api()

  const {http_web, URI_WEB_LIMPIA} = API_WEB();

  const[active,setActive] = useState(false)

  const[section, setSection] = useState('cuenta')

  const [posts, setPosts] = useState()
  const [postsFranquiciados, setPostsFranquiciados] = useState()
  const [recetas, setRecetas] = useState()
  const [listaBanners, setListaBanners] = useState([])
  const [listaPromociones, setListaPromociones] = useState([])
  const [promocionToUpdate, setPromocionToUpdate] = useState({
    _id:'',
    nombre:'',
    imagen:'',
    tipo:'',
    link:'',
    vencimiento:'',
    activo:''
  })
  
  const [postToUpdate, setPostToUpdate] = useState({
    id:'',
    title:'',
    description:'',
    URL:'',
    img:''
  })

  const [postToUpdateFranquiciados, setPostToUpdateFranquiciados] = useState({
    _id:'',
    title:'',
    description:'',
    URL:'',
    imageURL:''
  })

  const [recetasToUpdate, setRecetasToUpdate] = useState({
    _id:'',
    nombre:'',
    codigo_youtube:'',
  })

  const [bannerToUpdate,setBannerToUpdate] = useState({
    _id:'',
    nombre:'',
    imagen:'',
    imagen_pc:'',
    activo:'',
    vencimiento:''
  })

  const [banner,setBanner] = useState({
    type: '', number: ``
  })

  const [loading, setLoading] = useState(false)

  const [downLoadloading, setDownloadLoading] = useState(false)

  const [activeModalMod,setActiveModalMod] =useState(false)


  const [showAlert, setShowAlert] = useState(false)
  const [typeAlert, setTypeAlert] = useState('')
  const[message, setMessage] = useState('')

  const getTextForTipoPromocion = (tipo) => {
    switch (tipo) {
      case 0:
        return 'Promo del mes';
      case 1:
        return 'Bancaria';
      case 2:
        return 'Permanentes';
      default:
        return ''; // Maneja otros casos si es necesario
    }
  };

  const getActivo = (activo) => {
    switch (activo) {
      case 0:
        return 'Inactivo';
      case 1:
        return 'Activo';
      default:
        return ''; // Maneja otros casos si es necesario
    }
  };


  function formatearFecha(fecha) {
    const [anio, mes, dia] = fecha.split("-");
    return `${dia}/${mes}/${anio}`;
  }

  useEffect(() =>{
    window.scrollTo(0, 0)
    if(!posts) {
      let config = {
        headers: {
          // "Authorization": `Bearer ${token}`,
          "Accept": "application/json"
        }
    }

      http.get('api/posts',config).then((res) => {
          setPosts(res.data)
      } )
    }
},[posts])


useEffect(() =>{
  window.scrollTo(0, 0)
  if(!recetas || !postsFranquiciados || !listaBanners || !listaPromociones) {

  http_web.get('web_institucional/todos/').then((res) => {
    setRecetas(res.data[0].recetas)
    setPostsFranquiciados(res.data[0].post_franquiciados)
    setListaBanners(res.data[0].banners)
    setListaPromociones(res.data[0].promociones)
    } )
  }
},[recetas], [postsFranquiciados], [listaBanners], [listaPromociones])


  const token = getToken()

  const openCarouselModal = (carousel) => {
    if (carousel != null){
      setBannerToUpdate({
        _id:carousel._id,
        nombre:carousel.nombre,
        imagen:carousel.imagen,
        imagen_pc:carousel.imagen_pc,
        activo:carousel.activo,
        vencimiento:carousel.vencimiento})
        setCarouselActive(carousel)
    }else{
      setBannerToUpdate({
        _id:'',
        nombre:'',
        imagen:'',
        imagen_pc:'',
        activo:'',
        vencimiento:''})
        setCarouselActive({
          _id:'',
          nombre:'',
          imagen:'',
          imagen_pc:'',
          activo:'',
          vencimiento:''})
    }
    
    
    setActiveModalCarousel(true)
  }

  const openModalMod = () => {
    setBanner({type: 'modal', number: ``})
    setActiveModalMod(true)
  }

  const freshDataCerrar = () => {
    setActive(false)
    setActiveModalCarousel(false)
    setActiveModalMod(false)
    setPreviewImage('')
    setPreviewImageMovil('')
    setPreviewImagePromo('')
    setBannerToUpdate({
      _id:'',
      nombre:'',
      imagen:'',
      imagen_pc:'',
      activo:'',
      vencimiento:''})
    setPostToUpdate({
      id:'',
      title:'',
      description:'',
      URL:'',
      img:''
    })
    setPostToUpdateFranquiciados({
      _id:'',
      title:'',
      description:'',
      URL:'',
      imageURL:''
    })
    setRecetasToUpdate({
      _id:'',
      nombre:'',
      codigo_youtube:''
    })
    setPromocionToUpdate({
      id:'',
      nombre:'',
      imagen:'',
      tipo:'',
      link:'',
      vencimiento:'',
      activo:''
    })
  } 

  const freshData = () => {
    setActive(false)
    setActiveModalCarousel(false)
    setActiveModalMod(false)
    setPreviewImage('')
    setPreviewImageMovil('')
    setPreviewImagePromo('')
    setBannerToUpdate({
      _id:'',
      nombre:'',
      imagen:'',
      imagen_pc:'',
      activo:'',
      vencimiento:''})
    setPostToUpdate({
      id:'',
      title:'',
      description:'',
      URL:'',
      img:''
    })
    setPostToUpdateFranquiciados({
      _id:'',
      title:'',
      description:'',
      URL:'',
      imageURL:''
    })
    setRecetasToUpdate({
      _id:'',
      nombre:'',
      codigo_youtube:''
    })
    setPromocionToUpdate({
      id:'',
      nombre:'',
      imagen:'',
      tipo:'',
      link:'',
      vencimiento:'',
      activo:''
    })
    setPosts(null)
    setPostsFranquiciados(null)
    setRecetas(null)
    setListaBanners(null)
    setListaPromociones(null)
  } 

    const FormUpdatePost = (type) => {

      const token = getToken()

      let config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json"
          }
      }

      // let formData = new FormData()

      // formData.append('id',postToUpdate.id)
      // formData.append('title', postToUpdate.title)
      // formData.append('imageURL', postToUpdate.URL)
      // formData.append('description', postToUpdate.description)
      // formData.append('URL', postToUpdate.URL)

      if(type == 'post') {
        http.post('api/posts', {title:postToUpdate.title,imageURL:postToUpdate.img, description: postToUpdate.description, URL: postToUpdate.URL}, config)
        .then( res => {
          setMessage(res.data)
          setTypeAlert(() => 'green')
          freshData()
          setShowAlert(true)
          setTimeout(() => {
            setShowAlert(() => false)
          }, 3000)
          }).catch((err) => {
            console.log(err.response.data[0])
            setMessage(err.response.data[0])
            setTypeAlert(() => 'red')
            setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
          })
      }
      

      if(type == 'put'){
          http.put('api/posts', {id:postToUpdate.id,title:postToUpdate.title,imageURL:postToUpdate.img, description: postToUpdate.description, URL: postToUpdate.URL}, config)
          .then( res => {
            setMessage(res.data)
            setTypeAlert(() => 'green')
            freshData()
            setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            }).catch((err) => {
              console.log(err.response.data[0])
              setMessage(err.response.data[0])
              setTypeAlert(() => 'red')
              setTimeout(() => {
                setShowAlert(true)
                setShowAlert(() => false)
              }, 3000)
            })
      }else if (type == 'delete') {

          http.delete(`api/posts/${postToUpdate.id}`,config)
          .then( res => {
            setMessage(res.data)
            setTypeAlert(() => 'green')
            freshData()
            setShowAlert(true)
            setTimeout(() => {
              setShowAlert(() => false)
            }, 3000)
            }).catch((err) => {
              console.log(err.response.data[0])
              setMessage(err.response.data[0])
              setTypeAlert(() => 'red')
              setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            })
      }

  }

  const FormUpdatePostFranquiciados = (type) => {

    const token = getToken()

    let config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Accept": "application/json"
        }
    }

    // let formData = new FormData()

    // formData.append('id',postToUpdate.id)
    // formData.append('title', postToUpdate.title)
    // formData.append('imageURL', postToUpdate.URL)
    // formData.append('description', postToUpdate.description)
    // formData.append('URL', postToUpdate.URL)

    if(type == 'post') {
      console.log("Datos antes de la solicitud POST:", {
        title: postToUpdateFranquiciados.title,
        imageURL: postToUpdateFranquiciados.imageURL,
        description: postToUpdateFranquiciados.description,
        URL: postToUpdateFranquiciados.URL
      });
      http_web.post('prensa/', {title:postToUpdateFranquiciados.title,imageURL:postToUpdateFranquiciados.imageURL, description: postToUpdateFranquiciados.description, URL: postToUpdateFranquiciados.URL}, config)
      .then( res => {
        setMessage(res.data[0].dato)
        setTypeAlert(() => 'green')
        freshData()
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(() => false)
        }, 3000)
        }).catch((err) => {
          console.log(err.response.data[0])
          setMessage(err.response.data[0])
          setTypeAlert(() => 'red')
          setShowAlert(true)
            setTimeout(() => {
              setShowAlert(() => false)
            }, 3000)
        })
    }
    

    if(type == 'put'){

      console.log("Datos antes de la solicitud POST:", {
        _id: postToUpdateFranquiciados._id,
        title: postToUpdateFranquiciados.title,
        imageURL: postToUpdateFranquiciados.imageURL,
        description: postToUpdateFranquiciados.description,
        URL: postToUpdateFranquiciados.URL
      });

        http_web.post('prensa/', {_id:postToUpdateFranquiciados._id,
                              title:postToUpdateFranquiciados.title,
                              imageURL:postToUpdateFranquiciados.imageURL, 
                              description: postToUpdateFranquiciados.description, 
                              URL: postToUpdateFranquiciados.URL}, config)
        .then( res => {
          setMessage(res.data[0].dato)
          setTypeAlert(() => 'green')
          console.log("Respuesta exitosa:", res.data[0].dato);
          freshData()
          setShowAlert(true)
            setTimeout(() => {
              setShowAlert(() => false)
            }, 3000)
          }).catch((err) => {
            console.log(err.response.data[0])
            setMessage(err.response.data[0])
            setTypeAlert(() => 'red')
            setTimeout(() => {
              setShowAlert(true)
              setShowAlert(() => false)
            }, 3000)
          })
    }else if (type == 'delete') {

        http_web.delete(`prensa/eliminar/${postToUpdateFranquiciados._id}`,config)
        .then( res => {
          setMessage(res.data)
          setTypeAlert(() => 'green')
          freshData()
          setShowAlert(true)
          setTimeout(() => {
            setShowAlert(() => false)
          }, 3000)
          }).catch((err) => {
            console.log(err.response.data[0])
            setMessage(err.response.data[0])
            setTypeAlert(() => 'red')
            setShowAlert(true)
            setTimeout(() => {
              setShowAlert(() => false)
            }, 3000)
          })
    }

}

const FormUpdateRecetas = (type) => {

  const token = getToken()

  let config = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Accept": "application/json"
      }
  }

  if(type == 'post') {
    console.log("Datos antes de la solicitud POST:", {
      nombre: recetasToUpdate.nombre,
      codigo_youtube: recetasToUpdate.codigo_youtube
    });
    http_web.post('recetas/', {nombre:recetasToUpdate.nombre,codigo_youtube:recetasToUpdate.codigo_youtube}, config)
    .then( res => {
      setMessage(res.data[0].dato)
      setTypeAlert(() => 'green')
      freshData()
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(() => false)
      }, 3000)
      }).catch((err) => {
        console.log(err.response.data[0])
        setMessage(err.response.data[0])
        setTypeAlert(() => 'red')
        setShowAlert(true)
          setTimeout(() => {
            setShowAlert(() => false)
          }, 3000)
      })
  }
  

  if(type == 'put'){

    console.log("Datos antes de la solicitud POST:", {
      _id: recetasToUpdate._id,
      nombre: recetasToUpdate.nombre,
      codigo_youtube: recetasToUpdate.codigo_youtube
    });

      http_web.post('recetas/', {_id: recetasToUpdate._id,
                                  nombre: recetasToUpdate.nombre,
                                  codigo_youtube: recetasToUpdate.codigo_youtube}, config)
      .then( res => {
        setMessage(res.data[0].dato)
        setTypeAlert(() => 'green')
        console.log("Respuesta exitosa:", res.data[0].dato);
        freshData()
        setShowAlert(true)
          setTimeout(() => {
            setShowAlert(() => false)
          }, 3000)
        }).catch((err) => {
          console.log(err.response.data[0])
          setMessage(err.response.data[0])
          setTypeAlert(() => 'red')
          setTimeout(() => {
            setShowAlert(true)
            setShowAlert(() => false)
          }, 3000)
        })
  }else if (type == 'delete') {

      http_web.delete(`recetas/eliminar/${recetasToUpdate._id}`,config)
      .then( res => {
        setMessage(res.data)
        setTypeAlert(() => 'green')
        freshData()
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(() => false)
        }, 3000)
        }).catch((err) => {
          console.log(err.response.data[0])
          setMessage(err.response.data[0])
          setTypeAlert(() => 'red')
          setShowAlert(true)
          setTimeout(() => {
            setShowAlert(() => false)
          }, 3000)
        })
  }

}

    const handlePostModified = (e) => {
      setPostToUpdate({...postToUpdate,[e.target.name]: `${e.target.value}`})

    }

    const handlePostModifiedFranquiciados = (e) => {
      setPostToUpdateFranquiciados({...postToUpdateFranquiciados,[e.target.name]: `${e.target.value}`})
    }

    const handleRecetasModified = (e) => {
      setRecetasToUpdate({...recetasToUpdate,[e.target.name]: `${e.target.value}`})

    }

    const handlePromocionModified = (e) => {
      setPromocionToUpdate({...promocionToUpdate,[e.target.name]: `${e.target.value}`})
    }

    const handleClickPost = (e) => {
      if(e.target.id) {
        const postInfo = posts.filter((post) => post.id == e.target.id)[0]
        setPostToUpdate(postInfo)
      }
      setActive(true)
    }

    const handleClickPostFranquiciados = (e) => {
      if(e.target.id) {
        const postInfo = postsFranquiciados.filter((post) => post._id == e.target.id)[0]
        setPostToUpdateFranquiciados(postInfo)
      }
      setActive(true)
    }

    const handleClickPostPromos = (e) => {
      if(e.target.id) {
        const promocion = listaPromociones.filter((promo) => promo._id == e.target.id)[0]
        setPromocionToUpdate(promocion)
      }
      setActive(true)
    }

    const handleClickReceta = (e) => {
      if(e.target.id) {
        const postInfo = recetas.filter((post) => post._id == e.target.id)[0]
        setRecetasToUpdate(postInfo)
      }
      setActive(true)
    }

    const handleChangeImage = (e) => {
      const selectedImage = e.target.files[0];
  
      // Crear una URL local para la imagen seleccionada
      const imageUrl = URL.createObjectURL(selectedImage);
  
      // Actualizar el estado con la URL de la imagen
      setPreviewImage(imageUrl);
  
      // Actualizar el estado con la imagen seleccionada
      setCarouselImg(selectedImage);
    };

    const handleChangeImageMovil = (e) => {
      const selectedImage = e.target.files[0];
  
      // Crear una URL local para la imagen seleccionada
      const imageUrl = URL.createObjectURL(selectedImage);
  
      // Actualizar el estado con la URL de la imagen
      setPreviewImageMovil(imageUrl);
  
      // Actualizar el estado con la imagen seleccionada
      setBannerMovil(selectedImage);
    };

    const handleChangeImagePromo = (e) => {
      const selectedImage = e.target.files[0];
  
      // Crear una URL local para la imagen seleccionada
      const imageUrl = URL.createObjectURL(selectedImage);
  
      // Actualizar el estado con la URL de la imagen
      setPreviewImagePromo(imageUrl);
  
      // Actualizar el estado con la imagen seleccionada
      setPromoImg(selectedImage);
    };

    const handleNombreBannerChange = (e) => {
      // Obtener el nuevo valor del input
      const nuevoNombre = e.target.value;
    
      // Actualizar el estado con el nuevo valor
      setBannerToUpdate((prevCarouselActive) => ({
        ...prevCarouselActive,
        nombre: nuevoNombre,
      }));
    };

    const handleChangeVencimiento = (e) => {
      const nuevoVencimiento = e.target.value;

    
      setBannerToUpdate((prevCarouselActive) => ({
        ...prevCarouselActive,
        vencimiento: nuevoVencimiento,
      }));
    }

    const handleChangeVencimientoPromo = (e) => {
      const nuevoVencimiento = e.target.value;

    
      setPromocionToUpdate((prevPromoActive) => ({
        ...prevPromoActive,
        vencimiento: nuevoVencimiento,
      }));
    }

    const handleSwitchChange = (elemento) => {
      if (elemento == 0){
        setBannerToUpdate((prevCarouselActive) => ({
          ...prevCarouselActive,
          activo: !prevCarouselActive.activo
        }));
      }else if (elemento == 1){
        setPromocionToUpdate((prevPromoActive) => ({
          ...prevPromoActive,
          activo: !prevPromoActive.activo
        }));
      }
      
    };

    const handleNombrePromoChange = (e) => {
      // Obtener el nuevo valor del input
      const nuevoNombre = e.target.value;
    
      // Actualizar el estado con el nuevo valor
      setPromocionToUpdate((prevPromoActive) => ({
        ...prevPromoActive,
        nombre: nuevoNombre,
      }));
    };

    const handleTipoPromoChange = (e) => {
      // Obtener el nuevo valor del input
      const nuevoTipo = e.target.value;
    
      // Actualizar el estado con el nuevo valor
      setPromocionToUpdate((prevPromoActive) => ({
        ...prevPromoActive,
        tipo: nuevoTipo,
      }));
    };

    const handleLinkPromoChange = (e) => {
      // Obtener el nuevo valor del input
      const nuevoLink = e.target.value;
    
      // Actualizar el estado con el nuevo valor
      setPromocionToUpdate((prevPromoActive) => ({
        ...prevPromoActive,
        link: nuevoLink,
      }));
    };

    const logoutUser = () => {
      setLoading(true)
      const token = getToken()

      let config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Accept": "application/json"
        }
      }
      
      if(token !== undefined) {

          
          http.post('api/logout','',config)
          .then(result => {
              setLoading(false)
              logout()
          })
          .catch(error => {
            setLoading(false)
      
          });

          
      }
  }


    const handleClick = () => {

      let config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Accept": "application/json"
        }
      }

      setDownloadLoading(true)
      setTypeAlert(() => 'green')

      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${getToken()}`);

      var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
      };
      try {
        fetch(`${URI}/api/listaprecios`, requestOptions)
        .then( res => res.blob() )
        .then( blob => {
          var file = window.URL.createObjectURL(blob);
          window.location.assign(file);
          setDownloadLoading(false)
        })
      } catch(error) {
        setDownloadLoading(false)
  
      };
      }
  
    const FormSubmitBanner = (type) => {

      const token = getToken()

      let config = {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json",
            'Content-Type': 'multipart/form-data'
          }
      }

      let formData = new FormData()        

      if(type == 'post'){
          if (bannerMovil && carouselImg){

            formData.append('file', bannerMovil);
            formData.append('file2', carouselImg);
            formData.append('nombre', bannerToUpdate.nombre);
            formData.append('activo', bannerToUpdate.activo ? '1' : '0');
            formData.append('vencimiento', bannerToUpdate.vencimiento);

            console.log("Datos antes de la solicitud POST--:");

            for (var key of formData.entries()) {
              console.log(key[0] + ', ' + key[1]);
            }

            http_web.post('banners/', formData, config)
            .then( res => {
              setMessage(res.data[0].dato)
              setTypeAlert(() => 'green')
              console.log("Respuesta exitosa:", res.data[0].dato);
              freshData()
              setShowAlert(true)
                setTimeout(() => {
                  setShowAlert(() => false)
                }, 3000)
              }).catch((err) => {
                console.log(err.response.data[0])
                setMessage(err.response.data[0])
                setTypeAlert(() => 'red')
                setShowAlert(true)
                setTimeout(() => {
                  setShowAlert(() => false)
                }, 3000)
              })
          }else if (bannerMovil){
            formData.append('file', bannerMovil); // 'file2' debe coincidir con el nombre esperado en el backend
            formData.append('nombre', bannerToUpdate.nombre);
            formData.append('activo', bannerToUpdate.activo ? '1' : '0');
            formData.append('vencimiento', bannerToUpdate.vencimiento);

            console.log("Datos antes de la solicitud POST--:");

            for (var key of formData.entries()) {
              console.log(key[0] + ', ' + key[1]);
            }

            http_web.post('banners/', formData, config)
            .then( res => {
              setMessage(res.data[0].dato)
              setTypeAlert(() => 'green')
              console.log("Respuesta exitosa:", res.data[0].dato);
              freshData()
              setShowAlert(true)
                setTimeout(() => {
                  setShowAlert(() => false)
                }, 3000)
              }).catch((err) => {
                console.log(err.response.data[0])
                setMessage(err.response.data[0])
                setTypeAlert(() => 'red')
                setShowAlert(true)
                setTimeout(() => {
                  setShowAlert(() => false)
                }, 3000)
              })
          }else if (carouselImg){
            formData.append('file2', carouselImg);
            formData.append('nombre', bannerToUpdate.nombre);
            formData.append('activo', bannerToUpdate.activo ? '1' : '0');
            formData.append('vencimiento', bannerToUpdate.vencimiento);

            console.log("Datos antes de la solicitud POST--:");

            for (var key of formData.entries()) {
              console.log(key[0] + ', ' + key[1]);
            }

            http_web.post('banners/', formData, config)
            .then( res => {
              setMessage(res.data[0].dato)
              setTypeAlert(() => 'green')
              console.log("Respuesta exitosa:", res.data[0].dato);
              freshData()
              setShowAlert(true)
                setTimeout(() => {
                  setShowAlert(() => false)
                }, 3000)
              }).catch((err) => {
                console.log(err.response.data[0])
                setMessage(err.response.data[0])
                setTypeAlert(() => 'red')
                setShowAlert(true)
                setTimeout(() => {
                  setShowAlert(() => false)
                }, 3000)
              })
          }else{
            formData.append('nombre', bannerToUpdate.nombre);
            formData.append('activo', bannerToUpdate.activo ? '1' : '0');
            formData.append('vencimiento', bannerToUpdate.vencimiento);

            console.log("Datos antes de la solicitud POST--:");

            for (var key of formData.entries()) {
              console.log(key[0] + ', ' + key[1]);
            }

            http_web.post('banners/', formData, config)
            .then( res => {
              setMessage(res.data[0].dato)
              setTypeAlert(() => 'green')
              console.log("Respuesta exitosa:", res.data[0].dato);
              freshData()
              setShowAlert(true)
                setTimeout(() => {
                  setShowAlert(() => false)
                }, 3000)
              }).catch((err) => {
                console.log(err.response.data[0])
                setMessage(err.response.data[0])
                setTypeAlert(() => 'red')
                setShowAlert(true)
                setTimeout(() => {
                  setShowAlert(() => false)
                }, 3000)
              })
          }          
      }else if(type == 'put'){
        if (bannerMovil && carouselImg){
          formData.append('file', bannerMovil); // 'file2' debe coincidir con el nombre esperado en el backend
          formData.append('file2', carouselImg);
          formData.append('_id', bannerToUpdate._id);
          formData.append('nombre', bannerToUpdate.nombre);
          formData.append('activo', bannerToUpdate.activo ? '1' : '0');
          formData.append('vencimiento', bannerToUpdate.vencimiento);

          console.log("Datos antes de la solicitud POST--:");

          for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
          }

          http_web.post('banners/', formData, config)
          .then( res => {
            setMessage(res.data[0].dato)
            setTypeAlert(() => 'green')
            console.log("Respuesta exitosa:", res.data[0].dato);
            freshData()
            setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            }).catch((err) => {
              console.log(err.response.data[0])
              setMessage(err.response.data[0])
              setTypeAlert(() => 'red')
              setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            })
        }else if (bannerMovil){
          formData.append('file', bannerMovil); // 'file2' debe coincidir con el nombre esperado en el backend
          formData.append('_id', bannerToUpdate._id);
          formData.append('nombre', bannerToUpdate.nombre);
          formData.append('activo', bannerToUpdate.activo ? '1' : '0');
          formData.append('vencimiento', bannerToUpdate.vencimiento);

          console.log("Datos antes de la solicitud POST--:");

          for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
          }

          http_web.post('banners/', formData, config)
          .then( res => {
            setMessage(res.data[0].dato)
            setTypeAlert(() => 'green')
            console.log("Respuesta exitosa:", res.data[0].dato);
            freshData()
            setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            }).catch((err) => {
              console.log(err.response.data[0])
              setMessage(err.response.data[0])
              setTypeAlert(() => 'red')
              setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            })
        }else if (carouselImg){
          formData.append('file2', carouselImg);
          formData.append('_id', bannerToUpdate._id);
          formData.append('nombre', bannerToUpdate.nombre);
          formData.append('activo', bannerToUpdate.activo ? '1' : '0');
          formData.append('vencimiento', bannerToUpdate.vencimiento);

          console.log("Datos antes de la solicitud POST--:");

          for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
          }

          http_web.post('banners/', formData, config)
          .then( res => {
            setMessage(res.data[0].dato)
            setTypeAlert(() => 'green')
            console.log("Respuesta exitosa:", res.data[0].dato);
            freshData()
            setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            }).catch((err) => {
              console.log(err.response.data[0])
              setMessage(err.response.data[0])
              setTypeAlert(() => 'red')
              setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            })
        }else{

          formData.append('_id', bannerToUpdate._id);
          formData.append('nombre', bannerToUpdate.nombre);
          formData.append('activo', bannerToUpdate.activo ? '1' : '0');
          formData.append('vencimiento', bannerToUpdate.vencimiento);

          console.log("Datos antes de la solicitud POST--:");

          for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
          }

          http_web.post('banners/', formData, config)
          .then( res => {
            setMessage(res.data[0].dato)
            setTypeAlert(() => 'green')
            console.log("Respuesta exitosa:", res.data[0].dato);
            freshData()
            setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            }).catch((err) => {
              console.log(err.response.data[0])
              setMessage(err.response.data[0])
              setTypeAlert(() => 'red')
              setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            })
        } 
    }else if (type == 'delete') {

      http_web.delete('banners/eliminar/' + bannerToUpdate._id, config)
          .then( res => {
            setMessage(res.data)
            setTypeAlert(() => 'green')
            freshData()
            setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            }).catch((err) => {
              console.log(err.response.data[0])
              setMessage(err.response.data[0])
              setTypeAlert(() => 'red')
              setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            })
      }

  }

  const FormSubmitPromo = (type) => {

    const token = getToken()

    let config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Accept": "application/json",
          'Content-Type': 'multipart/form-data'
        }
    }

    let formData = new FormData()        

    if(type == 'post'){
        if (promoImg){
          formData.append('file', promoImg); // 'file2' debe coincidir con el nombre esperado en el backend
          formData.append('nombre', promocionToUpdate.nombre);
          formData.append('activo', promocionToUpdate.activo ? '1' : '0');
          formData.append('tipo', promocionToUpdate.tipo);
          formData.append('link', promocionToUpdate.link);
          formData.append('vencimiento', promocionToUpdate.vencimiento);

          console.log("Datos antes de la solicitud POST--:");

          for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
          }

          http_web.post('descuentos/', formData, config)
          .then( res => {
            setMessage(res.data[0].dato)
            setTypeAlert(() => 'green')
            console.log("Respuesta exitosa:", res.data[0].dato);
            freshData()
            setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            }).catch((err) => {
              console.log(err.response.data[0])
              setMessage(err.response.data[0])
              setTypeAlert(() => 'red')
              setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            })
        }else{
          formData.append('nombre', promocionToUpdate.nombre);
          formData.append('activo', promocionToUpdate.activo ? '1' : '0');
          formData.append('tipo', promocionToUpdate.tipo);
          formData.append('link', promocionToUpdate.link);
          formData.append('vencimiento', promocionToUpdate.vencimiento);

          console.log("Datos antes de la solicitud POST--:");

          for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
          }

          http_web.post('descuentos/', formData, config)
          .then( res => {
            setMessage(res.data[0].dato)
            setTypeAlert(() => 'green')
            console.log("Respuesta exitosa:", res.data[0].dato);
            freshData()
            setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            }).catch((err) => {
              console.log(err.response.data[0])
              setMessage(err.response.data[0])
              setTypeAlert(() => 'red')
              setShowAlert(true)
              setTimeout(() => {
                setShowAlert(() => false)
              }, 3000)
            })
        }          
    }else if(type == 'put'){
      if (promoImg){
        formData.append('file', promoImg); // 'file2' debe coincidir con el nombre esperado en el backend
        formData.append('_id', promocionToUpdate._id);
        formData.append('nombre', promocionToUpdate.nombre);
        formData.append('activo', promocionToUpdate.activo ? '1' : '0');
        formData.append('tipo', promocionToUpdate.tipo);
        formData.append('link', promocionToUpdate.link);
        formData.append('vencimiento', promocionToUpdate.vencimiento);

        console.log("Datos antes de la solicitud POST--:");

        for (var key of formData.entries()) {
          console.log(key[0] + ', ' + key[1]);
        }

        http_web.post('descuentos/', formData, config)
        .then( res => {
          setMessage(res.data[0].dato)
          setTypeAlert(() => 'green')
          console.log("Respuesta exitosa:", res.data[0].dato);
          freshData()
          setShowAlert(true)
            setTimeout(() => {
              setShowAlert(() => false)
            }, 3000)
          }).catch((err) => {
            console.log(err.response.data[0])
            setMessage(err.response.data[0])
            setTypeAlert(() => 'red')
            setShowAlert(true)
            setTimeout(() => {
              setShowAlert(() => false)
            }, 3000)
          })
      }else{
        formData.append('_id', promocionToUpdate._id);
        formData.append('nombre', promocionToUpdate.nombre);
        formData.append('activo', promocionToUpdate.activo ? '1' : '0');
        formData.append('tipo', promocionToUpdate.tipo);
        formData.append('link', promocionToUpdate.link);
        formData.append('vencimiento', promocionToUpdate.vencimiento);

        console.log("Datos antes de la solicitud POST--:");

        for (var key of formData.entries()) {
          console.log(key[0] + ', ' + key[1]);
        }

        http_web.post('descuentos/', formData, config)
        .then( res => {
          setMessage(res.data[0].dato)
          setTypeAlert(() => 'green')
          console.log("Respuesta exitosa:", res.data[0].dato);
          freshData()
          setShowAlert(true)
            setTimeout(() => {
              setShowAlert(() => false)
            }, 3000)
          }).catch((err) => {
            console.log(err.response.data[0])
            setMessage(err.response.data[0])
            setTypeAlert(() => 'red')
            setShowAlert(true)
            setTimeout(() => {
              setShowAlert(() => false)
            }, 3000)
          })
      } 
  }else if (type == 'delete') {
    http_web.delete('descuentos/eliminar/' + promocionToUpdate._id, config)
        .then( res => {
          setMessage(res.data)
          setTypeAlert(() => 'green')
          freshData()
          setShowAlert(true)
            setTimeout(() => {
              setShowAlert(() => false)
            }, 3000)
          }).catch((err) => {
            console.log(err.response.data[0])
            setMessage(err.response.data[0])
            setTypeAlert(() => 'red')
            setShowAlert(true)
            setTimeout(() => {
              setShowAlert(() => false)
            }, 3000)
          })
    }

}

  const FormSubmit = (type) => {

    const token = getToken()

    let config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Accept": "application/json"
        }
    }

    let formData = new FormData(type)

    formData.append('banner',carouselImg)
    formData.append('bannerMovil', bannerMovil)
    formData.append('number', banner.number)
    formData.append('type', banner.type)

    if(type == 'upload'){

        formData.append('banner',carouselImg)
        formData.append('bannerMovil', bannerMovil)
        formData.append('number', banner.number)
        formData.append('type', banner.type)

        http.post('api/banner', formData, config)
        .then( res => {
          setMessage(res.data)
          setTypeAlert(() => 'green')
          freshData()
          setShowAlert(true)
            setTimeout(() => {
              setShowAlert(() => false)
            }, 3000)
          }).catch((err) => {
            console.log(err.response.data[0])
            setMessage(err.response.data[0])
            setTypeAlert(() => 'red')
            setShowAlert(true)
            setTimeout(() => {
              setShowAlert(() => false)
            }, 3000)
          })
    }else if (type == 'delete') {

        http.delete(`api/banner/${banner.number}`, config)
        .then( res => {
          setMessage(res.data)
          setTypeAlert(() => 'green')
          freshData()
          setShowAlert(true)
            setTimeout(() => {
              setShowAlert(() => false)
            }, 3000)
          }).catch((err) => {
            console.log(err.response.data[0])
            setMessage(err.response.data[0])
            setTypeAlert(() => 'red')
            setShowAlert(true)
            setTimeout(() => {
              setShowAlert(() => false)
            }, 3000)
          })
    }

}

  return  (
    <div className='gridContainer'>
      <div className='menu boxShadow'>
        <h1 className='subtitle' style={{ color: '#FFF', textAlign: 'center' }}>Dashboard Admin</h1>
        <ul className='listaMenu'>
          <li onClick={() => setSection('cuenta')}>Cuenta</li>
          <li onClick={() => setSection('carouseles')}>Banners</li>
          <li onClick={() => setSection('modal')}>Modal</li>
          <li onClick={() => setSection('noticias')}>Noticias</li>
          <li onClick={() => setSection('noticias_franquiciados')}>Noticias Franquiciados</li>
          <li onClick={() => setSection('recetas')}>Recetas</li>
          <li onClick={() => setSection('productos')}>Productos</li>
          <li onClick={() => setSection('sucursales')}>Sucursales</li>
          <li onClick={() => setSection('promociones')}>Promociones</li>
        </ul>
      </div>
      <div className='container adminContainer' style={{ marginTop: '5rem', minHeight: '80vh', marginBottom: '3em' }}>
            {section == 'cuenta' && (
              <section style= {{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', margin: '2em 0'}}>
                <button 
                    className="btn btn__close" 
                    onClick={(e) => {
                      handleClick()
                    e.preventDefault()}}
                  >
                    Descargar Lista Precios
                  </button>
                  <button
                    className="btn btn__close" 
                    onClick={logoutUser}
                  >
                    Salir de la cuenta
                  </button>
              </section>
            )}
          {posts ? (
          <>
          {section == 'carouseles' && (
            <section>
              <h1 className='subtitle' style={{ textAlign: 'center' }}>BANNERS</h1>
              <div>
                {listaBanners.map(carousel => {
                  return (
                    <div style={{ border: '1px solid blue', padding: '10px', marginBottom: '1rem' }} key={carousel._id}>
                      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                        <div style={{ display: 'flex', flexDirection:'column', width: '250px' }}>
                          <p style={{color: 'var(--bg)', padding:'5px'}}>Imagen PC</p>
                          <img src={URI_WEB_LIMPIA+ "imagenes/banners/"+carousel.imagen_pc} alt={"carousel " + carousel.nombre}></img>
                          <p style={{color: 'var(--bg)', padding:'5px'}}>Imagen Móvil</p>
                          <img src={URI_WEB_LIMPIA+ "imagenes/banners/"+carousel.imagen} alt={"carousel " + carousel.nombre}></img>
                        </div>
                        <div>
                          <h2 style={{ textAlign: 'left', color: 'var(--bg)' }}>{carousel.nombre}</h2>
                          <p style={{color: 'var(--bg)', padding:'5px'}}>{"Activo: "+ carousel.activo}</p>
                          <p style={{color: 'var(--bg)', padding:'5px'}}>{"Vencimiento: "+ carousel.vencimiento}</p>
                          <button className='btn' onClick={() => openCarouselModal(carousel) }>Modificar</button>
                        </div>
                      </div>
                    </div>
                  )
                })}
                <div style={{ display:'flex', alignItems:'center', justifyContent:'center'}}>
                  <button className='btn' onClick={() => openCarouselModal(null) }>Nuevo Banner</button>
                </div>
                {activeModalCarousel && 
                  <Modal open={true} setActive={freshDataCerrar} width={'1200px'}>
                      <div style={{ width: '1200px', height:'80svh', color: 'var(--bg)', display: 'flex', flexDirection: 'column', overflow:'auto'}}>
                      {bannerToUpdate._id ? (
                        <div key={bannerToUpdate._id}>
                          <h3 className='subtitle'>Carousel {bannerToUpdate.nombre}</h3>
                          <form action="" id="form__field" className="contact__form container">
                            <div className="form__file field">
                              <div style={{with:'90%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                <p style={{color: 'var(--bg)', padding:'5px'}}>Imagen PC</p>
                                {/* Mostrar la imagen seleccionada en tiempo real o la actual del Banner */}
                                {previewImage ? (
                                  <img style={{ width: '60%' }} src={previewImage} alt={`carousel ${carouselActive.nombre}`} />
                                ):(
                                 <img style={{width:'60%'}} src={URI_WEB_LIMPIA+ "imagenes/banners/"+carouselActive.imagen_pc} alt={"carousel " + carouselActive.nombre}></img>
                                )}
                                <label htmlFor="banner" className="contact_label" >
                                  Subir Carousel
                                  <input 
                                    onChange={handleChangeImage} 
                                    id="banner" 
                                    type="file" 
                                    className="contact__input" 
                                    name="banner"
                                  />
                                </label>
                              </div>
                              <div style={{with:'90%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>                            
                                <p style={{color: 'var(--bg)', padding:'5px'}}>Imagen Móvil</p>
                                {previewImageMovil ? (
                                  <img style={{ width: '60%' }} src={previewImageMovil} alt={`carousel ${bannerToUpdate.nombre}`} />
                                ):(
                                 <img style={{width:'60%'}} src={URI_WEB_LIMPIA+ "imagenes/banners/"+bannerToUpdate.imagen} alt={`carousel ${bannerToUpdate.nombre}`}></img>
                                )}
                                <label htmlFor="banner" className="contact_label">
                                  Subir Carousel Movil
                                  <input 
                                    onChange={handleChangeImageMovil} 
                                    id="banner" 
                                    type="file" 
                                    className="contact__input" 
                                    name="banner"
                                  />
                                </label>
                              </div>
                              <div style={{with:'90%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>        
                                <label htmlFor="string" className="contact_label">
                                  Nombre
                                  <input
                                    id="date" 
                                    type="string"
                                    className="contact__input" 
                                    name="carousel"
                                    value={bannerToUpdate.nombre}
                                    onChange={handleNombreBannerChange}
                                  />
                                </label>
                              </div>
                              <div style={{with:'90%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>        
                                <label htmlFor="string" className="contact_label">
                                  Vencimiento
                                  <input
                                    id="string" 
                                    type="date"
                                    className="contact__input" 
                                    name="carousel"
                                    value={bannerToUpdate.vencimiento}
                                    onChange={handleChangeVencimiento}
                                  />
                                </label>
                              </div>
                            </div>
                            <div style={{ with: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                              <div className="form__number field" style={{ with: '90%' }}>
                                <label htmlFor="number" className="contact_label">
                                  Activo
                                  <Switch value={bannerToUpdate.activo} onChange={() => handleSwitchChange(0)} />
                                </label>
                              </div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems:'center', justifyContent:'center'}}>
                            <button 
                                className="btn btn__close" 
                                style={{ backgroundColor: 'darkRed' }}
                                onClick={(e) => {
                                  FormSubmitBanner('delete')
                                e.preventDefault()}}
                              >
                                Eliminar
                              </button>
                              <button 
                                className="btn btn__close" 
                                onClick={(e) => {
                                  FormSubmitBanner('put')
                                e.preventDefault()}}
                              >
                                Enviar
                              </button>
                              
                            </div>
                          </form>
                        </div>
                        ):(
                          <div key={bannerToUpdate._id}>
                          <h3 className='subtitle'>Carousel {bannerToUpdate.nombre}</h3>
                          <form action="" id="form__field" className="contact__form container">
                            <div className="form__file field">
                              <div style={{with:'90%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                <p style={{color: 'var(--bg)', padding:'5px'}}>Imagen PC</p>
                                {/* Mostrar la imagen seleccionada en tiempo real o la actual del Banner */}
                                {previewImage && <img style={{ width: '60%' }} src={previewImage} alt={`carousel ${carouselActive.nombre}`} />}
                                <label htmlFor="banner" className="contact_label" >
                                  Subir Carousel
                                  <input 
                                    onChange={handleChangeImage} 
                                    id="banner" 
                                    type="file" 
                                    className="contact__input" 
                                    name="banner"
                                  />
                                </label>
                              </div>
                              <div style={{with:'90%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>                            
                                <p style={{color: 'var(--bg)', padding:'5px'}}>Imagen Móvil</p>
                                {previewImageMovil && 
                                  <img style={{ width: '60%' }} src={previewImageMovil} alt={`carousel ${bannerToUpdate.nombre}`} />}
                                <label htmlFor="banner" className="contact_label">
                                  Subir Carousel Movil
                                  <input 
                                    onChange={handleChangeImageMovil} 
                                    id="banner" 
                                    type="file" 
                                    className="contact__input" 
                                    name="banner"
                                  />
                                </label>
                              </div>
                              <div style={{with:'90%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>        
                                <label htmlFor="string" className="contact_label">
                                  Nombre
                                  <input
                                    id="date" 
                                    type="string"
                                    className="contact__input" 
                                    name="carousel"
                                    value={bannerToUpdate.nombre}
                                    onChange={handleNombreBannerChange}
                                  />
                                </label>
                              </div>
                              <div style={{with:'90%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>        
                                <label htmlFor="string" className="contact_label">
                                  Vencimiento
                                  <input
                                    id="string" 
                                    type="date"
                                    className="contact__input" 
                                    name="carousel"
                                    value={bannerToUpdate.vencimiento}
                                    onChange={handleChangeVencimiento}
                                  />
                                </label>
                              </div>
                            </div>
                            <div style={{ with: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                              <div className="form__number field" style={{ with: '90%' }}>
                                <label htmlFor="number" className="contact_label">
                                  Activo
                                  <Switch value={bannerToUpdate.activo} onChange={handleSwitchChange} />
                                </label>
                              </div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems:'center', justifyContent:'center'}}>
                              <button 
                                className="btn btn__close" 
                                onClick={(e) => {
                                  FormSubmitBanner('post')
                                e.preventDefault()}}
                                disabled={!previewImage && !previewImageMovil}
                              >
                                Enviar
                              </button>
                              
                            </div>
                          </form>
                        </div>
                        )}
                      </div>
                  </Modal>
                }
              </div>
            </section>
          )}
          {section == 'modal' && (
            <section>
              <h1 className='subtitle' style={{ textAlign: 'center' }}>Modificacion Modal</h1>
              <div style={{ border: '1px solid blue', height: '300px', marginBottom: '1rem' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                        <div style={{ width: '250px' }}>
                          <img src={`http://api.luz-azul.com.ar/api/banner/modal`} alt='modal'></img>
                        </div>
                        <div>
                          <h2 style={{ textAlign: 'center', color: 'var(--bg)' }}>Modal</h2>
                          <button className='btn' onClick={() => openModalMod()}>Modificar</button>
                        </div>
                      </div>
                    </div>
              {activeModalMod && 
                  <Modal open={true} setActive={freshData} width={'1200px'}>
                  <div style={{ width: '1200px',color: 'var(--bg)', display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <h3 className='subtitle'>Modal</h3>
                      <form action="" id="form__field" className="contact__form container">
                        <div className="form__file field">
                          <label htmlFor="banner" className="contact_label" >
                            Subir Modal
                            <input 
                              onChange={handleChangeImage} 
                              id="banner" 
                              type="file" 
                              className="contact__input" 
                              name="banner"
                            />
                          </label>
                          <br></br>
                          <br></br>
                          <label htmlFor="string" className="contact_label">
                            Tipo de Imagen a Modificar
                            <input
                              id="string" 
                              type="string"
                              disabled
                              className="contact__input" 
                              name="modal"
                              value='modal'
                            />
                          </label>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'end'}}>
                          <button 
                            className="btn btn__close" 
                            onClick={(e) => {
                              FormSubmit('upload')
                            e.preventDefault()}}
                          >
                            Enviar
                          </button>
                          
                        </div>
                      </form>
                    </div>
                  </div>
                  </Modal>
                }
            </section>
          )}
          {section == 'noticias' && (
            <section>
              <h1 className='subtitle' style={{ textAlign: 'center' }}>Modificacion de Noticias</h1>
              <button className='btn' onClick={handleClickPost}>Crear Nueva Noticia</button>
              <table className='productDetail__table listaprecios__table' style={{ color:'var(--bg)', tableLayout: 'fixed', fontWeight: 'bold' }}>
                    <thead>
                      <tr>
                        <th>Titulo</th>
                        <th>Imagen</th>
                        <th>Descripcion</th>
                        <th>URL</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {posts.map(post => {
                        return(
                              <tr key={post.id}>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>{post.title}</td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>{post.img}</td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>{post.description}</td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>{post.URL}</td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>
                                  <button className='btn' id={post.id} onClick={handleClickPost}>Modificar</button>
                                </td>
                              </tr>
                          )
                        })
                      }
                    </tbody>
              </table>
                  {active && 
                    <Modal open={true} setActive={freshData} width={'1200px'}>
                        <div style={{ width: '1200px',color: 'var(--bg)', display: 'flex', flexDirection: 'column' }}>
                          {postToUpdate.id ? (
                            
                          <div key={postToUpdate.id}>
                            <h3 className='subtitle'>Articulo {postToUpdate.id}</h3>
                            <form>
                              <div className=" field">
                                <label htmlFor='title' className="contact_label">
                                  Titulo
                                  <input  
                                    id={postToUpdate.id}
                                    type="text"
                                    className="contact__input" 
                                    name='title'
                                    value={postToUpdate.title}
                                    onChange={handlePostModified}
                                  />
                                </label>
                              </div>
                              <div className=" field">
                                <label htmlFor='img' className="contact_label">
                                  Imagen
                                  <input  
                                    id={postToUpdate.id}
                                    type="text"
                                    className="contact__input" 
                                    name='img'
                                    value={postToUpdate.img}
                                    onChange={handlePostModified}
                                  />
                                </label>
                              </div>
                              <div className=" field">
                                <label htmlFor='description' className="contact_label" style={{ height: '500px' }}>
                                  Descripcion ({`${postToUpdate.description.length}`}/500)
                                  <textarea  
                                    id={postToUpdate.id}
                                    type="text"
                                    className="contact__input" 
                                    name='description'
                                    value={postToUpdate.description}
                                    onChange={handlePostModified}
                                  />
                                </label>
                              </div>
                              <div className=" field" >
                                <label htmlFor='URL' className="contact_label">
                                  URL
                                  <input  
                                    id={postToUpdate.id}
                                    type="text"
                                    className="contact__input" 
                                    name='URL'
                                    value={postToUpdate.URL}
                                    onChange={handlePostModified}
                                  />
                                </label>
                              </div>
                              <button className='btn' 
                                onClick={(e) => {
                                FormUpdatePost('put')
                                e.preventDefault()
                                }}
                              >
                                Actualizar
                              </button>
                              <button className='btn' 
                                id={postToUpdate.id}
                                onClick={(e) => {
                                  FormUpdatePost('delete')
                                  e.preventDefault()
                                  }
                                }
                                style={{ backgroundColor: 'darkRed' }}
                              >
                                Eliminar
                              </button>
                            </form>
                          </div>

                          ):(
                            <div >
                            <h3 className='subtitle'>Articulo Nuevo</h3>
                            <form>
                              <div className=" field">
                                <label htmlFor='title' className="contact_label">
                                  Titulo
                                  <input  
                                    type="text"
                                    className="contact__input" 
                                    name='title'
                                    value={postToUpdate.title}
                                    onChange={handlePostModified}
                                  />
                                </label>
                              </div>
                              <div className=" field">
                                <label htmlFor='img' className="contact_label">
                                  Imagen
                                  <input  
                                    type="text"
                                    className="contact__input" 
                                    name='img'
                                    value={postToUpdate.img}
                                    onChange={handlePostModified}
                                  />
                                </label>
                              </div>
                              <div className=" field">
                                <label htmlFor='description' className="contact_label" style={{ height: '500px' }}>
                                  Descripcion ({`${postToUpdate.description.length}`}/500)
                                  <textarea  
                                    type="text"
                                    className="contact__input" 
                                    name='description'
                                    value={postToUpdate.description}
                                    onChange={handlePostModified}
                                  />
                                </label>
                              </div>
                              <div className=" field" >
                                <label htmlFor='URL' className="contact_label">
                                  URL
                                  <input  
                                    type="text"
                                    className="contact__input" 
                                    name='URL'
                                    value={postToUpdate.URL}
                                    onChange={handlePostModified}
                                  />
                                </label>
                              </div>
                              <button className='btn' 
                                onClick={(e) => {
                                FormUpdatePost('post')
                                e.preventDefault()
                                }}
                              >
                                Subir
                              </button>
                            </form>
                          </div>
                          )}
                        </div>
                    </Modal>
                  }

            </section>
          )}
          {section == 'noticias_franquiciados' && (
            <section>
              <h1 className='subtitle' style={{ textAlign: 'center' }}>Modificacion de Noticias Franquiciados</h1>
              <button className='btn' onClick={handleClickPostFranquiciados}>Crear Nueva Noticia</button>
              <table className='productDetail__table listaprecios__table' style={{ color:'var(--bg)', tableLayout: 'fixed', fontWeight: 'bold' }}>
                    <thead>
                      <tr>
                        <th>Titulo</th>
                        <th>Imagen</th>
                        <th>Descripcion</th>
                        <th>URL</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {postsFranquiciados.map(post => {
                        return(
                              <tr key={post._id}>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>{post.title}</td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>{post.imageURL}</td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>{post.description}</td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>{post.URL}</td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>
                                  <button className='btn' id={post._id} onClick={handleClickPostFranquiciados}>Modificar</button>
                                </td>
                              </tr>
                          )
                        })
                      }
                    </tbody>
              </table>
                  {active && 
                    <Modal open={true} setActive={freshData} width={'1200px'}>
                        <div style={{ width: '1200px',color: 'var(--bg)', display: 'flex', flexDirection: 'column' }}>
                          {postToUpdateFranquiciados._id ? (
                            
                          <div key={postToUpdateFranquiciados._id}>
                            <h3 className='subtitle'>Noticia {postToUpdateFranquiciados._id}</h3>
                            <form>
                              <div className=" field">
                                <label htmlFor='title' className="contact_label">
                                  Titulo
                                  <input  
                                    id={postToUpdateFranquiciados._id}
                                    type="text"
                                    className="contact__input" 
                                    name='title'
                                    value={postToUpdateFranquiciados.title}
                                    onChange={handlePostModifiedFranquiciados}
                                  />
                                </label>
                              </div>
                              <div className=" field">
                                <label htmlFor='img' className="contact_label">
                                  Imagen
                                  <input  
                                    id={postToUpdateFranquiciados._id}
                                    type="text"
                                    className="contact__input" 
                                    name='imageURL'
                                    value={postToUpdateFranquiciados.imageURL}
                                    onChange={handlePostModifiedFranquiciados}
                                  />
                                </label>
                              </div>
                              <div className=" field">
                                <label htmlFor='description' className="contact_label" style={{ height: '500px' }}>
                                  Descripcion ({`${postToUpdateFranquiciados.description.length}`}/500)
                                  <textarea  
                                    id={postToUpdateFranquiciados._id}
                                    type="text"
                                    className="contact__input" 
                                    name='description'
                                    value={postToUpdateFranquiciados.description}
                                    onChange={handlePostModifiedFranquiciados}
                                  />
                                </label>
                              </div>
                              <div className=" field" >
                                <label htmlFor='URL' className="contact_label">
                                  URL
                                  <input  
                                    id={postToUpdateFranquiciados._id}
                                    type="text"
                                    className="contact__input" 
                                    name='URL'
                                    value={postToUpdateFranquiciados.URL}
                                    onChange={handlePostModifiedFranquiciados}
                                  />
                                </label>
                              </div>
                              <button className='btn' 
                                onClick={(e) => {
                                FormUpdatePostFranquiciados('put')
                                e.preventDefault()
                                }}
                              >
                                Actualizar
                              </button>
                              <button className='btn' 
                                id={postToUpdateFranquiciados._id}
                                onClick={(e) => {
                                  FormUpdatePostFranquiciados('delete')
                                  e.preventDefault()
                                  }
                                }
                                style={{ backgroundColor: 'darkRed' }}
                              >
                                Eliminar
                              </button>
                            </form>
                          </div>

                          ):(
                            <div >
                            <h3 className='subtitle'>Noticia Franquiciado Nuevo</h3>
                            <form>
                              <div className=" field">
                                <label htmlFor='title' className="contact_label">
                                  Titulo
                                  <input  
                                    type="text"
                                    className="contact__input" 
                                    name='title'
                                    value={postToUpdateFranquiciados.title}
                                    onChange={handlePostModifiedFranquiciados}
                                  />
                                </label>
                              </div>
                              <div className=" field">
                                <label htmlFor='img' className="contact_label">
                                  Imagen
                                  <input  
                                    type="text"
                                    className="contact__input" 
                                    name='imageURL'
                                    value={postToUpdateFranquiciados.imageURL}
                                    onChange={handlePostModifiedFranquiciados}
                                  />
                                </label>
                              </div>
                              <div className=" field">
                                <label htmlFor='description' className="contact_label" style={{ height: '500px' }}>
                                  Descripcion ({`${postToUpdateFranquiciados.description.length}`}/500)
                                  <textarea  
                                    type="text"
                                    className="contact__input" 
                                    name='description'
                                    value={postToUpdateFranquiciados.description}
                                    onChange={handlePostModifiedFranquiciados}
                                  />
                                </label>
                              </div>
                              <div className=" field" >
                                <label htmlFor='URL' className="contact_label">
                                  URL
                                  <input  
                                    type="text"
                                    className="contact__input" 
                                    name='URL'
                                    value={postToUpdateFranquiciados.URL}
                                    onChange={handlePostModifiedFranquiciados}
                                  />
                                </label>
                              </div>
                              <button className='btn' 
                                onClick={(e) => {
                                FormUpdatePostFranquiciados('post')
                                e.preventDefault()
                                }}
                              >
                                Subir
                              </button>
                            </form>
                          </div>
                          )}
                        </div>
                    </Modal>
                  }

            </section>
          )}
          {section == 'recetas' && (
            <section>
              <h1 className='subtitle' style={{ textAlign: 'center' }}>Modificacion de Recetas</h1>
              <button className='btn' onClick={handleClickPost}>Crear Nueva Receta</button>
              <table className='productDetail__table listaprecios__table' style={{ color:'var(--bg)', tableLayout: 'fixed', fontWeight: 'bold' }}>
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Código Youtube</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recetas.map(receta => {
                        return(
                              <tr key={receta._id}>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>{receta.nombre}</td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>{receta.codigo_youtube}</td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>
                                  <button className='btn' id={receta._id} onClick={handleClickReceta}>Modificar</button>
                                </td>
                              </tr>
                          )
                        })
                      }
                    </tbody>
              </table>
                  {active && 
                    <Modal open={true} setActive={freshData} width={'1200px'}>
                        <div style={{ width: '1200px',color: 'var(--bg)', display: 'flex', flexDirection: 'column' }}>
                          {recetasToUpdate._id ? (
                            
                          <div key={recetasToUpdate._id}>
                            <h3 className='subtitle'>Receta {recetasToUpdate._id}</h3>
                            <form>
                              <div className=" field">
                                <label htmlFor='title' className="contact_label">
                                  Nombre
                                  <input  
                                    id={recetasToUpdate._id}
                                    type="text"
                                    className="contact__input" 
                                    name='nombre'
                                    value={recetasToUpdate.nombre}
                                    onChange={handleRecetasModified}
                                  />
                                </label>
                              </div>
                              <div className=" field">
                                <label htmlFor='img' className="contact_label">
                                  Código Youtube
                                  <input  
                                    id={recetasToUpdate._id}
                                    type="text"
                                    className="contact__input" 
                                    name='codigo_youtube'
                                    value={recetasToUpdate.codigo_youtube}
                                    onChange={handleRecetasModified}
                                  />
                                </label>
                              </div>
                              <button className='btn' 
                                onClick={(e) => {
                                FormUpdateRecetas('put')
                                e.preventDefault()
                                }}
                              >
                                Actualizar
                              </button>
                              <button className='btn' 
                                id={recetasToUpdate._id}
                                onClick={(e) => {
                                  FormUpdateRecetas('delete')
                                  e.preventDefault()
                                  }
                                }
                                style={{ backgroundColor: 'darkRed' }}
                              >
                                Eliminar
                              </button>
                            </form>
                          </div>

                          ):(
                            <div >
                            <h3 className='subtitle'>Receta Nueva</h3>
                            <form>
                              <div className=" field">
                                <label htmlFor='title' className="contact_label">
                                  Nombre
                                  <input  
                                    type="text"
                                    className="contact__input" 
                                    name='nombre'
                                    value={recetasToUpdate.nombre}
                                    onChange={handleRecetasModified}
                                  />
                                </label>
                              </div>
                              <div className=" field">
                                <label htmlFor='img' className="contact_label">
                                  Código Youtube
                                  <input  
                                    type="text"
                                    className="contact__input" 
                                    name='codigo_youtube'
                                    value={recetasToUpdate.codigo_youtube}
                                    onChange={handleRecetasModified}
                                  />
                                </label>
                              </div>
                              
                              <button className='btn' 
                                onClick={(e) => {
                                FormUpdateRecetas('post')
                                e.preventDefault()
                                }}
                              >
                                Subir
                              </button>
                            </form>
                          </div>
                          )}
                        </div>
                    </Modal>
                  }

            </section>
          )}

          {section == 'productos' && (
            <ProductSection />
          )}
          {section == 'sucursales' && (
            <SucursalesSection />
          )}
          {section == 'promociones' && (
            <section>
              <h1 className='subtitle' style={{ textAlign: 'center' }}>Modificacion de Promociones</h1>
              <button className='btn' onClick={handleClickPostPromos}>Crear Nueva Promocion</button>
              <table className='productDetail__table listaprecios__table' style={{ color:'var(--bg)', tableLayout: 'fixed', fontWeight: 'bold' }}>
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Imagen</th>
                        <th>Tipo</th>
                        <th>Link</th>
                        <th>Vencimiento</th>
                        <th>Activo</th>
                        <th>Modificar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listaPromociones.map(promo => {
                        return(
                              <tr key={promo._id}>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>{promo.nombre}</td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)',  }}>
                                  <img style={{ width: '100px', height: '100px' }} src={URI_WEB_LIMPIA + "imagenes/descuentos_web/" + promo.imagen} alt={`promo ${promo.nombre}`} />
                                  </td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>{getTextForTipoPromocion(parseInt(promo.tipo))}</td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>{promo.link}</td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>{formatearFecha(promo.vencimiento)}</td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>{getActivo(parseInt(promo.activo))}</td>
                                <td style={{ wordWrap: 'break-word', padding: '1em', border: '1px solid var(--bg)' }}>
                                  
                                  <button className='btn' id={promo._id} onClick={handleClickPostPromos}>Modificar</button>
                                </td>
                              </tr>
                          )
                        })
                      }
                    </tbody>
              </table>
                  {active && 
                    <Modal open={true} setActive={freshDataCerrar} width={'1000px'}>
                        <div style={{ width: '1000px',color: 'var(--bg)', display: 'flex', flexDirection: 'column' }}>
                          {promocionToUpdate._id ? (
                          <div key={promocionToUpdate._id}>
                            <h3 className='subtitle'>Promocion {promocionToUpdate.nombre}</h3>
                            <form>
                              <div className=" field">
                                <label htmlFor='title' className="contact_label">
                                  Nombre
                                  <input  
                                    id={promocionToUpdate._id}
                                    type="text"
                                    className="contact__input" 
                                    name='title'
                                    value={promocionToUpdate.nombre}
                                    onChange={handleNombrePromoChange}
                                  />
                                </label>
                              </div>
                              <div className=" field" style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                <p style={{color: 'var(--bg)', padding:'5px'}}>Imagen</p>
                                  {/* Mostrar la imagen seleccionada en tiempo real o la actual */}
                                  {previewImagePromo ? (
                                    <img style={{ width: '60%' }} src={previewImagePromo} alt={`promo ${promocionToUpdate.imagen}`} />
                                  ):(
                                  <img style={{width:'200px'}} src={URI_WEB_LIMPIA+ "imagenes/descuentos_web/"+promocionToUpdate.imagen} alt={"carousel " + promocionToUpdate.nombre}></img>
                                  )}
                                  <label htmlFor="banner" className="contact_label" >
                                    Subir Imagen
                                    <input 
                                      onChange={handleChangeImagePromo} 
                                      id="banner" 
                                      type="file" 
                                      className="contact__input" 
                                      name="banner"
                                    />
                                  </label>
                              </div>
                              <div className=" field" style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                <label htmlFor='description' className="contact_label">
                                  Vencimiento
                                  <input  
                                    id={postToUpdateFranquiciados._id}
                                    type="date"
                                    className="contact__input" 
                                    name='description'
                                    value={promocionToUpdate.vencimiento}
                                    onChange={handleChangeVencimientoPromo}
                                    style={{ width: '200px'}}
                                  />
                                </label>
                              </div>
                              <div className=" field">
                                <label htmlFor='description' className="contact_label" style={{ height: '500px' }}>
                                  Tipo
                                  <select  
                                    id={postToUpdateFranquiciados._id}
                                    className="contact__input" 
                                    name='description'
                                    value={promocionToUpdate.tipo}
                                    onChange={handleTipoPromoChange}
                                  >
                                    <option value={0}>Promo del mes</option>
                                    <option value={1}>Bancaria</option>
                                    <option value={2}>Permanentes</option>
                                  </select>
                                </label>
                              </div>
                              <div className=" field" >
                                <label htmlFor='URL' className="contact_label">
                                  Link
                                  <input  
                                    id={promocionToUpdate._id}
                                    type="text"
                                    className="contact__input" 
                                    name='URL'
                                    value={promocionToUpdate.link}
                                    onChange={handleLinkPromoChange}
                                  />
                                </label>
                              </div>
                              <div className=" field" >
                                <div className="form__number field" style={{ with: '90%' }}>
                                  <label htmlFor="number" className="contact_label">
                                    Activo
                                    <Switch value={promocionToUpdate.activo} onChange={() => handleSwitchChange(1)} />
                                  </label>
                                </div>
                              </div>
                              <button className='btn' 
                                onClick={(e) => {
                                  FormSubmitPromo('put')
                                e.preventDefault()
                                }}
                              >
                                Actualizar
                              </button>
                              <button className='btn' 
                                id={promocionToUpdate._id}
                                onClick={(e) => {
                                  FormSubmitPromo('delete')
                                  e.preventDefault()
                                  }
                                }
                                style={{ backgroundColor: 'darkRed' }}
                              >
                                Eliminar
                              </button>
                            </form>
                          </div>

                          ):(
                            <div >
                            <h3 className='subtitle'>Promoción Nueva</h3>
                            <form>
                              <div className=" field">
                                <label htmlFor='title' className="contact_label">
                                  Nombre
                                  <input  
                                    type="text"
                                    className="contact__input" 
                                    name='title'
                                    value={promocionToUpdate.nombre}
                                    onChange={handleNombrePromoChange}
                                  />
                                </label>
                              </div>
                              <div className=" field" style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                <p style={{color: 'var(--bg)', padding:'5px'}}>Imagen</p>
                                  {/* Mostrar la imagen seleccionada en tiempo real o la actual */}
                                  {previewImagePromo && <img style={{ width: '60%' }} src={previewImagePromo} alt={`promo ${promocionToUpdate.imagen}`} />}
                                  <label htmlFor="banner" className="contact_label" >
                                    Subir Imagen
                                    <input 
                                      onChange={handleChangeImagePromo} 
                                      id="banner" 
                                      type="file" 
                                      className="contact__input" 
                                      name="banner"
                                    />
                                  </label>
                              </div>
                              <div className=" field">
                                <label htmlFor='description' className="contact_label" style={{ height: '500px' }}>
                                  Tipo
                                  <select  
                                    id={postToUpdateFranquiciados._id}
                                    className="contact__input" 
                                    name='description'
                                    value={promocionToUpdate.tipo}
                                    onChange={handleTipoPromoChange}
                                  >
                                    <option value={0}>Promo del mes</option>
                                    <option value={1}>Bancaria</option>
                                    <option value={2}>Permanentes</option>
                                  </select>
                                </label>
                              </div>
                              <div className=" field" style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                <label htmlFor='description' className="contact_label">
                                  Vencimiento
                                  <input  
                                    id={postToUpdateFranquiciados._id}
                                    type="date"
                                    className="contact__input" 
                                    name='description'
                                    value={promocionToUpdate.vencimiento}
                                    onChange={handleChangeVencimientoPromo}
                                    style={{ width: '200px'}}
                                  />
                                </label>
                              </div>
                              <div className=" field" >
                                <label htmlFor='URL' className="contact_label">
                                  Link
                                  <input  
                                    type="text"
                                    className="contact__input" 
                                    name='URL'
                                    value={promocionToUpdate.link}
                                    onChange={handleLinkPromoChange}
                                  />
                                </label>
                              </div>
                              <div className=" field" >
                                <div className="form__number field" style={{ with: '90%' }}>
                                  <label htmlFor="number" className="contact_label">
                                    Activo
                                    <Switch value={promocionToUpdate.activo} onChange={() => handleSwitchChange(1)} />
                                  </label>
                                </div>
                              </div>
                              <button className='btn' 
                                onClick={(e) => {
                                  FormSubmitPromo('post')
                                e.preventDefault()
                                }}
                              >
                                Subir
                              </button>
                            </form>
                          </div>
                          )}
                        </div>
                    </Modal>
                  }

            </section>
          )}
          </>
          ):(<Spinner />)}
          
          
          {loading ? <Spinner />:''}
          {showAlert ? (
          <Alert type={typeAlert}>
            {message}
          </Alert>): ('')
          }
            
            {downLoadloading ? (
            <><Alert type={typeAlert}>
            Descargando Archivo
          </Alert>
          <Spinner /></>): ''}
      </div>
    </div>
      )
}
