import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const API_WEB = () => {

    // const URI = 'http://localhost:8000'
    // const URI = 'https://apitest.luz-azul.com.ar'
    const URI_WEB = 'https://luz-azul.com.ar/API_WEB/'
    const URI_WEB_LIMPIA = 'https://luz-azul.com.ar/'
    

    const navigate = useNavigate();


    const http_web = axios.create({
        baseURL: URI_WEB,
        withCredentials: true,
    })

    return {
        http_web,
        URI_WEB,
        URI_WEB_LIMPIA
    }
}