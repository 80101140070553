import React from 'react';

const Switch = ({ value, onChange }) => {

    
  return (
    <label className="switch-container">
      <input type="checkbox" checked={value} onChange={onChange} />
      <span className="slider"></span>
    </label>
  );
};

export default Switch;