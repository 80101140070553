import {paths, social_externalLinks} from "routes/paths"

export const InfoPromosVigentesFranquiciados = [
    {
        url: 'https://drive.google.com/drive/folders/1Tu_Frj-Dtb6vg5kDYYRtD96us2gxVZPh',
        label: '01 - PROMOS DEL MES',
        target: '_blank'
    },
    {
        url: 'https://drive.google.com/drive/folders/1p384_3A-2m-34SInWm9PcZexKOJgGLet',
        label: '02 - PROMOS BANCARIAS',
        target: '_blank'
    },
    {
        url: 'https://drive.google.com/drive/folders/1VWYu3R_oOhSBwyjCFD0BGbKS0kQUmzQg',
        label: '03 - PERMANENTES',
        target: '_blank'
    },
    {
        url: paths.materialFranquiciados.url,
        label: 'VOLVER',
        target: '_self'
    }
]