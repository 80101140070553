import React,{useEffect, useState} from 'react'
import { Spinner } from 'components/Spinner';
import './Promociones.css'
import { API_WEB } from 'utils/api_web';

export const Promociones = () => {

    const {http_web} = API_WEB();

    const [descuentos, setDescuentos] = useState([]);
    const [loader, setLoader] = useState(false)
    const [activeButton, setActiveButton] = useState('todos');
    const [columns, setColumns] = useState(4);

    const URI_IMAGENES = 'https://luz-azul.com.ar/imagenes/descuentos_web/'

    useEffect(() => {
        window.scrollTo(0, 0)

        http_web.get('descuentos/activos/').then((res) => {
          setDescuentos(res.data)
          setLoader(true)
      } )
      }, [])

    // Función para filtrar los descuentos según el botón activo
    const filtrarDescuentos = () => {
        switch (activeButton) {
        case 'todos':
            return descuentos; // No aplicar filtro
        case 'promosMes':
            return descuentos.filter((descuento) => descuento.tipo === '0');
        case 'promosBancarias':
            return descuentos.filter((descuento) => descuento.tipo === '1');
        case 'promosPermanentes':
            return descuentos.filter((descuento) => descuento.tipo === '2');
        default:
            return descuentos; // Por defecto, no aplicar filtro
        }
    };

    const descuentosFiltrados = filtrarDescuentos();


  return (
    <div>
        <h1 className='title'>Promociones Vigentes</h1>
        <div className="buttons-container">
            <button
            className={`descuentos-button ${activeButton === 'todos' ? 'active' : ''}`}
            onClick={() => setActiveButton('todos')}
            >
            Todos
            </button>
            <button
            className={`descuentos-button ${activeButton === 'promosMes' ? 'active' : ''}`}
            onClick={() => setActiveButton('promosMes')}
            >
            Promos de mes
            </button>
            <button
            className={`descuentos-button ${activeButton === 'promosBancarias' ? 'active' : ''}`}
            onClick={() => setActiveButton('promosBancarias')}
            >
            Promos Bancarias
            </button>
            <button
            className={`descuentos-button ${activeButton === 'promosPermanentes' ? 'active' : ''}`}
            onClick={() => setActiveButton('promosPermanentes')}
            >
            Promos
            </button>
        </div>
        <span className='divider'></span>
        {activeButton === 'promosBancarias' ? <p style={{ color: 'var(--text-color-alt)', textAlign: 'center', fontSize: '1.2rem', padding:'1rem'}}>* Para ver las legales de cada promoción hacé clic en cada imagen</p> : ''}
        {activeButton === 'todos' ? <p style={{ color: 'var(--text-color-alt)', textAlign: 'center', fontSize: '1.2rem', padding:'1rem'}}>* Para ver las legales de cada promoción hacé clic en cada imagen</p> : ''}
        <div className='descuentos-container'>
            {loader ? 
                descuentosFiltrados.map((param) => (
                    <a
                        key={param._id} 
                        href={param.link ? param.link : '#'} // Si no hay enlace, se usa '#'
                        target={param.link ? '_blank' : '_self'} // Si hay enlace, se abre en una nueva pestaña (_blank)
                        rel={param.link ? 'noopener noreferrer' : ''} // Añadir seguridad en caso de abrir en una nueva pestaña
                    >
                        <img 
                            src={URI_IMAGENES + param.imagen} 
                            alt={param.nombre}
                        />
                    </a>
                )):
                <Spinner />}
        </div>     
                
    </div>
  )
}
